import { Trans, useTranslation } from '@getpopsure/i18n-react';

import shieldQuestionMark from './assets/shield-questionmark.svg';
import { Field } from 'react-final-form';

export const ConfirmNotSignedUpByEmployer = () => {
  const { t } = useTranslation();

  return (
    <Field name="confirmNotSignedUpByEmployer" initialValue="true">
      {() => (
        <div className="mx-auto ta-center wmx8">
          <img src={shieldQuestionMark} alt="" className="mb16" />
          <h1 className="p-h1 mb8" data-cy="questionnaire-title">
            {t(
              'page.question.confirmNotSignedUpByEmployer.title',
              'Your employer might have signed you up already'
            )}
          </h1>
          <Trans i18nKey="page.question.confirmNotSignedUpByEmployer.description">
            <p className="p-p mb24">
              You have the freedom to choose your health insurance provider up
              until 14 days after starting your new employment. If you haven’t
              discussed health insurance with your employer and it’s been more
              than 14 days, they might have signed you up for coverage already.
            </p>
            <p className="p-p">
              If you spoke with your employer and they didn’t insure you yet,
              please proceed with your application here.
            </p>
          </Trans>
        </div>
      )}
    </Field>
  );
};
