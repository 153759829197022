import { TranslationFn } from '@getpopsure/i18n-react';
import { Country, universities } from '@getpopsure/public-models';
import EmailValidator from 'email-validator';

import { Address, Name, PlaceOfBirth, University } from 'models';

export type ValidationErrorTypes = boolean | 'MIN_LENGTH';

export const getValidationErrors = (
  errorKey: ValidationErrorTypes,
  t: TranslationFn
) => {
  if (errorKey === 'MIN_LENGTH') {
    return t(
      'errors.validation.minLength',
      'Please provide more details. The description should be at least 75 characters.'
    );
  }

  return '';
};

export const alwaysValid = () => true;

export const nonEmpty = (input?: string | null) => {
  if (input === undefined || input === null || input.length === 0) {
    return false;
  }

  return true;
};

export const minLength = (input?: string | null, stringLength = 75) => {
  if (input === undefined || input === null || input.length < stringLength) {
    return 'MIN_LENGTH';
  }

  return true;
};

export const email = (input?: string) => {
  if (input) {
    return EmailValidator.validate(input);
  }

  return false;
};

export const address = (input?: Partial<Address>) => {
  if (!input) {
    return false;
  }

  const { street, houseNumber, postcode, city, country: aCountry } = input;

  if (!street || !houseNumber || !postcode || !city || !aCountry) {
    return false;
  }

  return true;
};

export const name = (input?: Partial<Name>): input is Name => {
  if (!input) {
    return false;
  }

  const { firstName, lastName } = input;

  return !!firstName && !!lastName;
};

export const isCountry = (input?: Country): boolean =>
  input?.code !== undefined && input?.name !== undefined;

export const isArrayOfCountries = (input?: Country[]): boolean => {
  if (Array.isArray(input) && input.length > 0) {
    const countries = input?.filter((country) => isCountry(country) === true);
    return input.length === countries.length;
  }
  return false;
};

export const validUniversity = (input?: University): boolean => {
  if (input?.isListed === false) {
    return (
      !!input?.name && ((input.address && address(input.address)) || false)
    );
  }
  return (
    universities.findIndex((university) => university === input?.name) !== -1
  );
};

export const validPoB = (input?: PlaceOfBirth) => {
  if (input && 'place' in input && 'country' in input) {
    return nonEmpty(input.place) && isCountry(input.country);
  }
  return false;
};
