import { MonthlyIncome } from 'models';
import { insurance } from '@getpopsure/private-constants';

const miniJobMonthlySalary = insurance.healthInsurance.miniJobThreshold;
const privateHealthMonthlySalary =
  insurance.healthInsurance.privateHealthInsuranceThreshold;

export const getSalaryType = (
  annualIncome: number | undefined
): MonthlyIncome => {
  const monthlyIncome = annualIncome ? annualIncome / 12 : 0;

  if (monthlyIncome > privateHealthMonthlySalary) {
    return 'EXCEED_PRIVATE_THRESHOLD';
  }

  if (monthlyIncome > miniJobMonthlySalary) {
    return 'UNDER_PRIVATE_THRESHOLD';
  }

  return 'MINI_JOB';
};
