import { website } from '@getpopsure/private-constants';

import styles from './style.module.scss';

const FeatherTechnologyLogo = () => (
  <a
    href={website.base}
    target="_blank"
    rel="noopener noreferrer"
    className={styles.container}
  >
    <div className={styles['powered-technology']} />
  </a>
);

export default FeatherTechnologyLogo;
