type IsEnabled = (flagName: string) => boolean;

class FeatureFlagService {
  /**
   * Returns the isEnabled function from unleash
   * that allows to check a feature flag by its name.
   *
   * If not set, for safety reasons, this always returns false.
   */
  static isEnabled: IsEnabled = (flagName: string) => false;

  /**
   * Allows to update the internal isEnabled property to use an external function.
   * This function comes from unleash.
   */
  public setIsEnabled(isEnabled: IsEnabled) {
    FeatureFlagService.isEnabled = isEnabled;
  }

  /**
   * This method allows to check if a flag is currently enabled.
   */
  public isEnabled(flagName: string): boolean {
    return FeatureFlagService.isEnabled(flagName);
  }
}

const FeatureFlag = new FeatureFlagService();

export { FeatureFlag };
