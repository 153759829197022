import { AppState } from 'reducers';
import { MetadataState } from 'reducers/metadata';
import { getQuestionOrder } from 'reducers/questionnaire/order';
import { getSalaryType } from 'util/getSalaryType';
import { isEmployed } from 'util/isEmployed';

export const selectQuestionnaireSelector = (state: AppState) =>
  state.questionnaire;

export const selectQuestionnaireWithoutUnecessaryAnswers = (
  state: AppState
) => {
  const order: string[] = getQuestionOrder(state.questionnaire);
  let questionnaire = state.questionnaire;

  Object.keys(questionnaire).forEach((key) => {
    if (order.includes(key) === false) {
      questionnaire = { ...state.questionnaire, [key]: undefined };
    }
  });

  const { name, ...rest } = questionnaire;

  return {
    salary: isEmployed(questionnaire?.occupation)
      ? getSalaryType(questionnaire?.annualIncome || 0)
      : undefined,
    ...name,
    ...rest,
  };
};

// TODO: Don't know why this is needed
export const selectAgentMetadata = (state: AppState) =>
  (state.metadata as MetadataState).agent;

export const selectOccupation = (state: AppState) =>
  state.questionnaire.occupation;
