import { Locale, changeLanguage, language } from '@getpopsure/i18n-react';
import { Fragment, useEffect, useRef, useState } from 'react';

import styles from './style.module.scss';

type LocaleEntry = { id: Locale; short: string; long: string };

const locales: LocaleEntry[] = [
  { id: 'en', short: 'EN', long: 'English' },
  { id: 'de', short: 'DE', long: 'Deutsch' },
];

interface Props {
  className?: string;
  type?: 'inline' | 'dropdown';
}

const LanguageSelector = ({ className, type = 'dropdown' }: Props) => {
  const [selected, setSelected] = useState<string>('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const buttonContainerRef = useRef<HTMLDivElement | null>(null);

  /**
   * Wrap the language() function inside a useEffect to allow cleaning up
   * when the component returns null
   */
  useEffect(() => {
    setSelected(language());
    return () => {
      setSelected('');
    };
  }, []);

  useEffect(() => {
    const listener = (event: Event) => {
      if (
        !buttonContainerRef.current ||
        buttonContainerRef.current.contains(event.target as Node)
      ) {
        return;
      }
      setMenuIsOpen(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [buttonContainerRef]);

  const handleChangeLanguage = (locale: Locale) => {
    setSelected(locale);
    changeLanguage(locale);
  };

  if (type === 'inline') {
    return (
      <div className={`d-flex ${className ?? ''}`}>
        {locales.map((locale, index) => (
          <Fragment key={locale.id}>
            <div
              className={`${
                styles[
                  locale.id === selected ? 'selector--toggled' : 'selector'
                ]
              }`}
              onClick={() => handleChangeLanguage(locale.id)}
            >
              {locale.short}
            </div>
            {index < locales.length - 1 ? (
              <span className={styles.spacer}>/</span>
            ) : (
              ''
            )}
          </Fragment>
        ))}
      </div>
    );
  }

  return (
    <div
      ref={buttonContainerRef}
      style={{
        position: 'relative',
      }}
      className={className}
    >
      <button
        className={`d-flex jc-between ai-center c-pointer br8 ${styles.dropdownButton}`}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        type="button"
      >
        {(selected || locales[0].id).toUpperCase()}
        <div className={styles.icon} />
      </button>
      {menuIsOpen && (
        <div className={styles.dropdownContainer}>
          {locales.map((locale) => (
            <button
              key={locale.id}
              onClick={(e) => {
                e?.preventDefault();
                setMenuIsOpen(false);
                handleChangeLanguage(locale.id);
              }}
              type="button"
              className={locale.id === selected ? styles.currentLocale : ''}
            >
              {locale.long}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
