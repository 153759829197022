interface ParamsObject {
  key: string;
  value: string;
}

export const paramsSetUrl = (
  baseUrl: string,
  params: ParamsObject[]
): string => {
  const urlParams = new URLSearchParams({});
  params.forEach(({ key, value }) => {
    if (!key || !value) {
      return;
    }
    urlParams.set(key, value);
  });
  return `${baseUrl}?${String(urlParams)}`;
};
