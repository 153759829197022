import { routes } from 'routes';
import { Link } from 'react-router-dom';

import styles from '../style.module.scss';
import { QuestionId } from 'reducers/questionnaire';
import { ReactNode } from 'react';

const LinkToQuestionButton = ({
  questionId,
  children,
}: {
  children: ReactNode;
  questionId: QuestionId;
}) => (
  <Link
    data-cy="link-to-question"
    className={`p-btn p-btn--secondary-white ${styles.button}`}
    to={`${routes.questionnaire.path}/${questionId}`}
  >
    {children}
  </Link>
);

export default LinkToQuestionButton;
