import Session from '@getpopsure/session';
import { getUser } from 'services/api';

export const isAuthenticatedAndSameEmail = async (email: string) => {
  if (!Session.isAuthenticated) {
    return false;
  }

  const { data } = await getUser();

  return email.toLowerCase() === data.email.toLowerCase();
};
