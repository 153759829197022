import { Reducer } from 'react';
import { AutoFillUserAction, SignOutUserAction } from 'actions/session';

export type AutoFillState = boolean;

const initialState: AutoFillState = false;

const autofill: Reducer<
  AutoFillState,
  AutoFillUserAction | SignOutUserAction
> = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTOFILL_USER':
      return true;
    case 'SIGN_OUT':
      return false;
    default:
      return state;
  }
};

export default autofill;
