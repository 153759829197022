import { Reducer } from 'react';
import { MetadataActions } from 'actions/metadata';
import { QuestionnaireAction } from 'actions/questionnaire';

export interface MetadataState {
  agent?: string;
}

const initialState: MetadataState = {};

const metadata: Reducer<
  MetadataState,
  MetadataActions | QuestionnaireAction
> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_QUESTIONNAIRE_AGENT':
      return { ...state, agent: action.agent };
    case 'CLEAR_QUESTIONNAIRE':
      return {};
    default:
      return state;
  }
};

export default metadata;
