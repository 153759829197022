import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Button, Input } from '@popsure/dirty-swan';
import { useDispatch } from 'react-redux';

import {
  getUser,
  sendSignInEmail,
  signInWithTemporaryLoginCode,
} from 'services/api';
import { formatErrorMessageFromError } from 'reducers/request';

import styles from './style.module.scss';
import { autofillAnswerFromUserAction } from 'actions/session';
import { useTranslation } from '@getpopsure/i18n-react';

type Context = 'SESSION_EXPIRED' | 'EXISTING_ACCOUNT';

const SignIn = ({
  email,
  onAuthenticationSuccess,
  context,
}: {
  email: string;
  onAuthenticationSuccess: () => void;
  context: Context;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loginCode, setLoginCode] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isInvalidLoginCode, setIsInvalidLoginCode] = useState(false);

  useEffect(() => {
    onSendSignInEmail();
    // eslint-disable-next-line
  }, []);

  const onLoginCodeEntered = async () => {
    setLoading(false);
    setError(null);
    try {
      setLoading(true);
      await signInWithTemporaryLoginCode(loginCode, email);
      const { data: user } = await getUser();
      dispatch(autofillAnswerFromUserAction(user));
      onAuthenticationSuccess();
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 422) {
        setIsInvalidLoginCode(true);
      } else {
        setError(formatErrorMessageFromError(e as AxiosError));
      }
    } finally {
      setLoading(false);
    }
  };

  const onSendSignInEmail = async () => {
    setLoading(true);
    setError(null);
    try {
      setLoading(true);
      await sendSignInEmail(email);
    } catch (e) {
      setError(formatErrorMessageFromError(e as AxiosError));
    } finally {
      setLoading(false);
    }
  };

  const description =
    context === 'EXISTING_ACCOUNT'
      ? t(
          'modal.signin.standard',

          'We’ve sent a code to {{email}}. Please enter the code below.',

          { email }
        )
      : t(
          'modal.signin.expired',
          'It looks like your session expired. We’ve sent a code to {{email}}. Please enter the code below to log in.',

          { email }
        );

  return (
    <div className={styles.container}>
      <p className="p-p mb16">{description}</p>
      <div
        role="button"
        className={`p-h4 p-a c-pointer`}
        onClick={onSendSignInEmail}
      >
        {t('modal.signin.newcode.action', 'Send a new code')}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onLoginCodeEntered();
        }}
      >
        <Input
          data-cy="login-code-input"
          className={`mt24 wmx5 ${styles.input}`}
          placeholder={t('modal.signin.input.placeholder', 'Log in code')}
          error={error ?? undefined}
          value={loginCode}
          onChange={(e) => {
            setLoginCode(e.target.value);
            setIsInvalidLoginCode(false);
          }}
          required={true}
        />
        {isInvalidLoginCode === true && (
          <>
            <p className="p-p--small tc-red-500 mt8 mb16">
              {t(
                'modal.signin.incorrect.description',

                'The code you entered was not recognised. Please try again or'
              )}{' '}
            </p>
            <div
              role="button"
              className="p-p--small pt p-a c-pointer"
              onClick={onSendSignInEmail}
            >
              {t('modal.signin.incorrect.action', 'Send a new code')}
            </div>
          </>
        )}
        <Button
          data-cy="login-code-sign-in"
          className={`p-btn--primary mt24 wmn3 ${styles.button}`}
          loading={loading}
          disabled={loading || loginCode.length === 0}
        >
          {t('modal.signin.action', 'Sign in')}
        </Button>
      </form>
    </div>
  );
};

export default SignIn;
