import { FlagContext } from '@unleash/proxy-client-react';
import { useContext, useEffect } from 'react';
import { FeatureFlag } from './FeatureFlagService';

function useFeatureFlagService() {
  const { client, isEnabled } = useContext(FlagContext);

  useEffect(() => {
    if (!client || !isEnabled) {
      return;
    }

    // Set FeatureFlag internal isEnabled function
    // as the one coming from unleash client
    const flagHandler = () => FeatureFlag.setIsEnabled(isEnabled);

    // Everytime client is ready or updated,
    // the FeatureFlagService isEnabled function is updated
    client.on('update', flagHandler);
    client.on('ready', flagHandler);

    return () => {
      client.off('update', flagHandler);
      client.off('ready', flagHandler);
    };
  }, [client, isEnabled]);
}

export { useFeatureFlagService };
