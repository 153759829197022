import { Field } from 'react-final-form';

import { DateSelector } from '@popsure/dirty-swan';

const DateQuestion = ({
  name,
  yearBoundaries,
}: {
  name: string;
  yearBoundaries: { min: number; max: number };
}) => (
  <Field name={name}>
    {(props) => (
      <DateSelector
        onChange={(newValue) => {
          props.input.onChange(newValue);
        }}
        value={props.input.value}
        yearBoundaries={yearBoundaries}
      />
    )}
  </Field>
);

export default DateQuestion;
