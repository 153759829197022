const formatWord = (word: string) => {
  if (word.length === 0) {
    return '';
  }

  // Camel case to separate words
  const [firstLetter, ...rest] = word
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .split('');

  // Capitalize first letter
  return `${firstLetter.toUpperCase()}${rest.join('')}`;
};
const getDetailsFromPath = (path: string) => {
  // Return the last part of the path as the title's "details"
  return formatWord(path.split('/').pop()?.replaceAll('-', ' ') || '');
};

export const generatePageTitle = (path: string) => getDetailsFromPath(path);
