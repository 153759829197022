import { Reducer } from 'redux';
import { createSelector } from 'reselect';
import { AxiosError } from 'axios';

import { RequestAction, RequestType } from 'actions/request';

interface RequestState {
  [id: string]: {
    inProcess: boolean;
    error?: AxiosError;
  };
}

const initialState: RequestState = {};

const request: Reducer<RequestState, RequestAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'REQUEST_IN_PROCESS':
      return {
        ...state,
        [action.requestType]: {
          inProcess: action.inProcess,
          error: undefined,
        },
      };
    case 'REQUEST_ERRORED':
      return {
        ...state,
        [action.requestType]: {
          inProcess: false,
          error: action.error,
        },
      };
    case 'FLUSH_REQUEST_ERROR':
      return {
        ...state,
        [action.requestType]: {
          inProcess: false,
          error: undefined,
        },
      };
    default:
      return state;
  }
};

export default request;

export const formatErrorMessageFromError = (error: AxiosError): string => {
  return error.message || 'Something went wrong';
};

export const getRequestInProcess = (
  state: RequestState,
  requestType: RequestType
) => state[requestType] && state[requestType].inProcess;

export const getRequestError = (
  state: RequestState,
  requestType: RequestType
) => state[requestType] && state[requestType].error;

export const getRequestErrorMessage = createSelector(
  getRequestError,
  (error?: AxiosError) => error && formatErrorMessageFromError(error)
);
