import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { captureException } from '@sentry/browser';

import rootSaga from 'sagas';
import sessionReducer from './session';
import questionnaireReducer from './questionnaire';
import requestReducer from './request';
import metadataReducer from './metadata';
import { referralEngineReducer } from 'features/referralEngine';

import { name } from '../../package.json';

const rootReducer = combineReducers({
  questionnaire: questionnaireReducer,
  request: requestReducer,
  metadata: metadataReducer,
  session: sessionReducer,
  referralEngine: referralEngineReducer,
});
export type AppState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(
  { key: name, storage, blacklist: ['request'] },
  rootReducer
);

function configureStore() {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
      captureException(error);
      if (process.env.NODE_ENV === 'development') {
        // tslint:disable-next-line:no-console
        console.error(error);
      }
    },
  });
  const middlewares = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(persistedReducer, {}, composedEnhancers);
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}

export const { store, persistor } = configureStore();
