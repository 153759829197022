import { Button } from '@popsure/dirty-swan';
import { skippedQuestion } from 'actions/questionnaire';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { QuestionId } from 'reducers/questionnaire';

const NextQuestionButton = ({
  questionId,
  ...rest
}: {
  className?: string;
  children: ReactNode;
  variant?: 'filledColor' | 'textColor';
  questionId: QuestionId;
}) => {
  const dispatch = useDispatch();
  const onSkipQuestion = () => {
    dispatch(skippedQuestion(questionId));
  };

  return (
    <Button data-cy="continue-button" {...rest} onClick={onSkipQuestion} />
  );
};

export default NextQuestionButton;
