import { useTranslation } from '@getpopsure/i18n-react';

import styles from './style.module.scss';

const SpouseBlocker = () => {
  const { t } = useTranslation();
  return (
    <div className="p-body public-signup-center-element">
      <div className={styles.container}>
        <div className={styles.emoji}>
          <span role="img" aria-label="">
            💌
          </span>
        </div>
        <div className={`p-h1 ${styles.title}`}>
          {t('page.blocker.spouse.title', 'Thanks for choosing us!')}
        </div>
        <div className={`p-p ${styles.subtitle}`}>
          {t(
            'page.blocker.spouse.description',
            'We have sent you an email which is a specific application for your situation.'
          )}
        </div>
      </div>
    </div>
  );
};

export default SpouseBlocker;
