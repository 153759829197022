import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { provider, Provider, providerNameMapping } from 'util/environment';
import { TranslationFn, useTranslation } from '@getpopsure/i18n-react';

import Card, { CardInfo } from 'components/card';
import signUpIcon from './img/sign-up.svg';
import emailIcon from './img/email.svg';
import passportIcon from './img/passport.svg';
import cardIcon from './img/card.svg';
import wavingHand from './img/waving-hand.svg';
import styles from './style.module.scss';

const getSteps = (provider: Provider, t: TranslationFn): Array<CardInfo> => [
  {
    id: 1,
    title: t('page.intro.step.1.title', 'Sign up'),
    description: t(
      'page.intro.step.1.description',
      'Apply for your new public health insurance with {{provider}}.',
      {
        provider: providerNameMapping[provider],
      }
    ),
    icon: {
      src: signUpIcon,
      alt: t('page.intro.step.1.icon.alt', 'Sign up icon'),
    },
  },
  {
    id: 2,
    title: t('page.intro.step.2.title', 'Confirmation of coverage', {
      provider: providerNameMapping[provider], // For German translation
    }),
    description: t(
      'page.intro.step.2.description',
      'You will usually get notified by email within 2 weeks.'
    ),
    icon: {
      src: emailIcon,
      alt: t('page.intro.step.2.icon.alt', 'Email icon'),
    },
  },
  {
    id: 3,
    title: t('page.intro.step.3.title', 'Upload photo'),
    description:
      provider === 'tk'
        ? t(
            'page.intro.step.3.description.tk',
            'TK will send you a letter with the instructions'
          )
        : t('page.intro.step.3.description', 'Done digitally in your account.'),
    icon: {
      src: passportIcon,
      alt: t('page.intro.step.3.icon.alt', 'Photo icon'),
    },
  },
  {
    id: 4,
    title: t('page.intro.step.4.title', 'Receive health card'),
    description: t(
      'page.intro.step.4.description',
      'By mail, within 2-3 weeks after submitting your photo.'
    ),
    icon: {
      src: cardIcon,
      alt: t('page.intro.step.4.icon.alt', 'Card icon'),
    },
  },
];

const Intro = () => {
  const { t } = useTranslation();
  return (
    <div
      className={`p-body public-signup-center-element jc-center ${styles.container}`}
    >
      <div>
        <img alt="" aria-hidden src={wavingHand} />
        <h1 className="p-h1 mt24">
          {t('page.intro.title', 'Let’s get you insured!')}
        </h1>
        <div className={`mt32 ${styles['steps-container']}`}>
          {getSteps(provider, t).map((step) => (
            <Card
              key={step.id}
              title={step.title}
              description={step.description}
              icon={step.icon}
            />
          ))}
        </div>
        <Link
          data-cy="intro-get-started"
          className={`p-btn p-btn--primary mt40 mb72 w100 wmx3 ${styles.button}`}
          to={routes.questionnaire.question.first.path}
        >
          {t('page.intro.action', 'Continue')}
        </Link>
      </div>
    </div>
  );
};

export default Intro;
