import FeatherTechnology from './featherTechnology';
import styles from './style.module.scss';
import { provider } from 'util/environment';

import LanguageSelector from 'components/languageSelector';

const Header = () => (
  <div className={`p-body ${styles.header}`}>
    <div className={`${styles.logo} ${styles[provider]}`} />
    <div className="d-flex ai-center ml-auto">
      <LanguageSelector className="mr24" />
      <FeatherTechnology />
    </div>
  </div>
);

export default Header;
