import { AutoSuggestInput, Input } from '@popsure/dirty-swan';
import { useTranslation, detectLocale, Locale } from '@getpopsure/i18n-react';

import { useState } from 'react';
import { Field } from 'react-final-form';
import { isMobile } from 'util/isMobile';
import {
  getAllCountries,
  associatedCountryFlagForCountryCode,
} from '@getpopsure/public-models';

import styles from './style.module.scss';

interface Option {
  value: string;
  leftIcon?: string;
}

const PlaceAndCountryOfBirthQuestion = ({ ...props }) => {
  const locale = detectLocale() as Locale;
  const allCountries = getAllCountries(locale);
  const [city, setCity] = useState(props.input.value?.place ?? '');
  const [country, setCountry] = useState(
    props.input.value?.country?.name ?? ''
  );
  const { t } = useTranslation();

  const [suggestions, setSuggestions] = useState<Option[]>([]);

  const handleSelectCountry = (value: string | Option) => {
    const selectedValue = typeof value === 'string' ? value : value.value;
    setCountry(selectedValue);
    const selectedCountry = allCountries.find(
      (country) => country.name === selectedValue
    );
    props.input.onChange({
      ...props.input.value,
      country: selectedCountry,
    });
  };

  const handleSuggestionFetchRequest = ({ value }: Option) => {
    const filteredOptions = allCountries
      .filter((country) =>
        country.name.toLowerCase().startsWith(value.toLowerCase())
      )
      .map((country) => ({
        value: country.name,
        leftIcon: associatedCountryFlagForCountryCode(country.code),
      }));
    setSuggestions(filteredOptions);
  };

  return (
    <div className={`wmx5 ${styles.container}`}>
      <Input
        name="place"
        value={city}
        onChange={(e) => {
          setCity(e.target.value);
          props.input.onChange({
            ...props.input.value,
            place: e.target.value,
          });
        }}
        placeholder={t(
          'page.question.placeandcountryofbirth.city.input.placeholder',
          'City of birth'
        )}
        data-cy="cityOfBirth"
        required={true}
      />
      <div className="mt16">
        <AutoSuggestInput
          currentOption={country}
          className="wmx5"
          placeholder={t(
            'page.question.placeandcountryofbirth.country.input.placeholder',
            'Country of birth'
          )}
          suggestions={suggestions}
          onChange={handleSelectCountry}
          handleSuggestionSelected={handleSelectCountry}
          handleSuggestionFetchRequest={handleSuggestionFetchRequest}
          handleSuggestionClearRequest={() => setSuggestions([])}
        />
      </div>
    </div>
  );
};

const QuestionWithField = ({ name }: { name: string }) => (
  <Field name={name} autoFocus={!isMobile}>
    {(props) => <PlaceAndCountryOfBirthQuestion {...props} />}
  </Field>
);

export default QuestionWithField;
