export interface SetQuestionnaireAgent {
  type: 'SET_QUESTIONNAIRE_AGENT';
  agent: string;
}

export type MetadataActions = SetQuestionnaireAgent;

export function setQuestionnaireAgent(agent: string): SetQuestionnaireAgent {
  return {
    type: 'SET_QUESTIONNAIRE_AGENT',
    agent,
  };
}
