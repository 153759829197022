import { init, detectLocale } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/browser';

import { translations } from 'locales';

const isStagingOrDev =
  process.env.REACT_APP_ENVIRONMENT === 'staging' ||
  process.env.NODE_ENV === 'development';

const initI18n = () =>
  init({
    phrase: {
      projectId: '1149a6d1353ebe5c7becfb48ea1a3b9c',
      allowInContextEditor: isStagingOrDev,
    },
    resources: translations,
    saveMissing: true,
    lng: detectLocale(),
    missingKeyHandler: (_lng, _ns, key) => {
      Sentry.captureMessage(
        `[Translation] Missing key: ${key}`,
        Sentry.Severity.Error
      );
    },
  });

export default initI18n;
