import styles from './StudentNextSteps.module.scss';

/**
 * StudentNextSteps.tsx
 *
 * This is a placeholder loader page that gets displayed
 * while the application redirects students to the app.
 */
export const StudentNextSteps = () => {
  return (
    <div className={`d-flex jc-center ai-center ${styles.container}`}>
      <div className="ds-spinner ds-spinner__l" />
    </div>
  );
};
