import { useDispatch } from 'react-redux';
import { routes } from 'routes';
import { flushQuestionsAction } from 'actions/questionnaire';
import { Link } from 'react-router-dom';
import { useTranslation } from '@getpopsure/i18n-react';

import styles from '../style.module.scss';

const StudentToEmployeeButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleButtonClick = () =>
    dispatch(flushQuestionsAction(['university', 'worksMoreThanTwentyHours']));

  return (
    <Link
      data-cy="student-to-employee"
      className={`p-btn p-btn--primary ${styles.button}`}
      onClick={handleButtonClick}
      to={`${routes.questionnaire.path}/occupation`}
    >
      {t('page.blocker.studenttoemployee.action', 'Continue as employee')}
    </Link>
  );
};

export default StudentToEmployeeButton;
