import { ReferralEngineActions } from '../actions';
import { UserReferralInfo } from '../models';

export const referralEngineReducer = (
  state: UserReferralInfo = {},
  action: ReferralEngineActions
) => {
  switch (action.type) {
    case 'UPDATE_REFERRAL_CODE_INFO':
      return {
        ...state,
        ...action.referralInfo,
      };
    default:
      return state;
  }
};
