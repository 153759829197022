import * as referralEngineApi from '../api';
import { call, put, take } from 'redux-saga/effects';
import { REFERRAL_VERIFY_OPERATION_ID } from '../constants';
import { AxiosResponse } from 'axios';
import { IsEligibleUserResponse } from '../models';
import { updateReferralInfo } from '../actions';
import {
  setRequestInProcess,
  setRequestFinished,
  setRequestErrored,
} from 'actions/request';

export function* verifyReferralCode(api = referralEngineApi) {
  while (true) {
    const actionType = 'REFERRAL_CODE_VERIFY';

    try {
      const { referralCode }: { referralCode: string } = yield take(actionType);

      yield put(setRequestInProcess(REFERRAL_VERIFY_OPERATION_ID));
      const { data }: AxiosResponse<IsEligibleUserResponse> = yield call(
        api.isEligibleUser,
        {
          referralCode,
        }
      );
      if (data.isEligibleUser) {
        yield put(
          updateReferralInfo({
            referralCode,
            referralCodeError: undefined,
          })
        );
      }

      if (!data.isEligibleUser) {
        yield put(
          updateReferralInfo({
            referralCodeError: data.reason,
          })
        );
      }

      yield put(setRequestFinished(REFERRAL_VERIFY_OPERATION_ID));
    } catch (error) {
      yield put(setRequestErrored(REFERRAL_VERIFY_OPERATION_ID, error));
    }
  }
}
