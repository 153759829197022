import { Trans } from '@getpopsure/i18n-react';

export const ConfirmSignedUpByEmployerCta = () => (
  <Trans i18nKey="page.question.confirmNotSignedUpByEmployer.cta">
    <button
      data-cy="questionnaire-continue-button"
      className="p-btn p-btn--secondary wmn3 d-block mx-auto bg-white"
      type="submit"
    >
      Continue sign-up
    </button>
  </Trans>
);
