import { QuestionnaireState, QuestionId } from '..';
import { getQuestionOrder } from '../order';

export const getProgress = (
  state: QuestionnaireState,
  questionId: QuestionId
): number => {
  const order = (() => {
    if (state.occupation === undefined) {
      return getQuestionOrder({ ...state, occupation: 'EMPLOYED' });
    } else {
      return getQuestionOrder(state);
    }
  })();

  const totalNumberOfQuestions = order.length;
  const currentQuestionIndex = order.findIndex(e => {
    return e === questionId;
  });

  if (currentQuestionIndex === -1) {
    return 0;
  }

  return (currentQuestionIndex / (totalNumberOfQuestions - 1)) * 100;
};
