export const EMPLOYED_MIN_JOB_START_DATE = 12;

/**
 * Maximum number of days an employed person can
 * voluntarily apply to Public Health Insurance.
 * After that, they are automatically signed-up by employers.
 */
export const EMPLOYED_MAX_JOB_START_DATE = 42;

/**
 * Number of days after the employer may insure the employee automatically.
 * After this value, we have to make sure that the customer has not been
 * insured by their employer.
 * This applies only to AOK.
 */
export const AOK_EMPLOYED_JOB_START_DATE_EMPLOYER_THRESHOLD = 24;
