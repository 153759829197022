import { cloneElement, isValidElement, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Redirect } from 'react-router-dom';
import { BottomOrRegularModal } from '@popsure/dirty-swan';

import Footer from 'containers/footer';
import { ViewProps } from 'containers/questionnaire';
import SignIn from 'components/signIn';
import { QuestionId } from 'reducers/questionnaire';
import {
  Name,
  Gender,
  DateOfBirth,
  Address,
  Occupation,
  UniversityName,
  JobTitle,
  EmployerName,
  EmployerAddress,
  BeginningOfContract,
  HasAddressInGermany,
  AnnualIncome,
  FirstTimeEmployedInGermany,
  PreviousInsurer,
  HasDependentChildren,
  ReceivesPension,
  Nationality,
  PlaceAndCountryOfBirth,
  GermanPensionNumber,
  LastNameAtBirth,
  PreviousCountryOfInsurance,
  OtherSituation,
  HowLongInLanguageSchool,
  CoverageStartDate,
  HasTaxNumber,
  MonthlyIncomeForNextYear,
  AgenturFurArbeitBenefits,
  HasOtherIncome,
  OtherIncome,
  OtherIncomeMonthlyRevenue,
  UniversityStartDate,
  DependentsToGetCovered,
  WorksMoreThanTwentyHours,
  UniversityInGermany,
  ConfirmNotSignedUpByEmployer,
  HasPublicHealthInsurance,
  HasGermanPublicHealthInsurance,
  InsuranceTwelveMonths,
  InsuranceTwentyFourMonths,
  PastFiveYearsInGermany,
  HadOneDayPublicHealthInsurance,
  ExpatOrPrivateHealthInsuranceIncoming,
  EHICCard,
  OtherSituationDescription,
  UnlistedPreviousInsurer,
  CurrentlyOnPrivate,
  LastInsuranceInGermany,
  EmploymentWithinTwoMonthsOfArrival,
  LastProviderInGermany,
} from 'components/questionnaire/questions';

import validator from './validators';

import {
  Employed,
  GeneralInformations,
  Student,
  FinalInformations,
  InsuranceHistory,
  Other,
  SelfEmployed,
  TaxInformation,
} from 'models';

import styles from './style.module.scss';
import EmailQuestion from './questions/emailQuestion';
import { useTranslation } from '@getpopsure/i18n-react';
import Blocker from 'components/pages/blocker';
import { setCheckoutStarted } from 'services/api';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getValidationErrors } from 'validators';

const Questionnaire = ({
  initialData,
  isLoading,
  questionId,
  onAnsweredQuestion,
  isSkippable,
  onSkip,
  reachableQuestions,
  needsAuthentication,
  error,
  customCta,
  hideCta,
}: ViewProps) => {
  const [displaySignInModal, setDisplaySingInModal] = useState(false);
  const { t } = useTranslation();

  const email = useSelector(
    (state: AppState) => state.questionnaire.email ?? ''
  );
  useEffect(() => {
    setDisplaySingInModal(needsAuthentication);
  }, [needsAuthentication]);

  useEffect(() => {
    if (email) {
      setCheckoutStarted(email);
    }
  }, [email]);

  if (reachableQuestions.indexOf(questionId) === -1) {
    return <Redirect to={reachableQuestions[reachableQuestions.length - 1]} />;
  }

  const onSubmitQuestion = (data: any) => {
    /**
     * confirmNotSignedUpByEmployer question does not have an input component
     * so we have to default the submit value to true
     */
    if (questionId === 'confirmNotSignedUpByEmployer') {
      onAnsweredQuestion(true);
      return;
    }

    onAnsweredQuestion(data[questionId]);
  };

  const getSkipLabel = (id: QuestionId): string => {
    switch (id) {
      case 'previousInsurer':
      case 'lastProviderInGermany':
        return t(
          'page.questionnaire.skip.previousinsurer',
          'My provider is not listed'
        );
      default:
        return t('page.questionnaire.skip.action', 'Skip');
    }
  };

  return (
    <>
      <div className="p-body public-signup-center-element">
        <div className={styles.container}>
          <Form
            initialValues={initialData}
            onSubmit={onSubmitQuestion}
            validate={(values) => {
              const validation = validator[questionId](values[questionId]);
              const errorMessage = validation
                ? getValidationErrors(validation, t)
                : '';

              return {
                [questionId]: validation !== true ? errorMessage : undefined,
              };
            }}
            render={({ handleSubmit, invalid }) => {
              if (questionId === 'email') {
                return <EmailQuestion />;
              }

              return (
                <>
                  <form onSubmit={handleSubmit}>
                    {viewForQuestion[questionId]}
                    {!hideCta && (
                      <div className="mt24">
                        {customCta && isValidElement(customCta()) ? (
                          cloneElement(customCta())
                        ) : (
                          <button
                            data-cy="questionnaire-continue-button"
                            className={`p-btn p-btn--primary wmn3 ${
                              isLoading ? 'p-btn--loading' : ''
                            }`}
                            type="submit"
                            disabled={invalid || isLoading}
                          >
                            {t(
                              'page.questionnaire.continue.action',
                              'Continue'
                            )}
                          </button>
                        )}
                        {isSkippable && (
                          <button
                            data-cy="questionnaire-skip-button"
                            className="p-btn--secondary wmn2 ml8"
                            disabled={isLoading}
                            onClick={onSkip}
                            type="button"
                          >
                            {getSkipLabel(questionId)}
                          </button>
                        )}
                      </div>
                    )}
                  </form>
                  {error && <div className="p-p p-error">{error}</div>}
                </>
              );
            }}
          />
        </div>
      </div>
      <Footer questionId={questionId} />
      <BottomOrRegularModal
        title={t('modal.signin.title', 'Sign in to Feather')}
        isOpen={displaySignInModal}
        onClose={() => setDisplaySingInModal(false)}
      >
        <SignIn
          email={initialData?.email!}
          context="SESSION_EXPIRED"
          onAuthenticationSuccess={() => {
            setDisplaySingInModal(false);
            setTimeout(() => {
              onAnsweredQuestion((initialData as any)[questionId]);
            }, 300);
          }}
        />
      </BottomOrRegularModal>
    </>
  );
};

export default Questionnaire;

const viewForGeneralQuestions: Record<
  keyof Required<GeneralInformations>,
  JSX.Element
> = {
  name: <Name />,
  hasAddressInGermany: <HasAddressInGermany />,
  email: <EmailQuestion />,
  dateOfBirth: <DateOfBirth />,
  gender: <Gender />,
  address: <Address />,
  occupation: <Occupation />,
};

const viewForEmployedQuestions: Record<
  keyof Required<Employed>,
  JSX.Element
> = {
  annualIncome: <AnnualIncome />,
  previousCountryOfInsurance: <PreviousCountryOfInsurance />,
  firstTimeEmployedInGermany: <FirstTimeEmployedInGermany />,
  previousInsurer: <PreviousInsurer />,
  unlistedPreviousInsurer: <UnlistedPreviousInsurer />,
  employerName: <EmployerName />,
  companyAddress: <EmployerAddress />,
  jobTitle: <JobTitle />,
  jobStartDate: <BeginningOfContract />,
  coverageStartDate: <CoverageStartDate />,
  confirmNotSignedUpByEmployer: <ConfirmNotSignedUpByEmployer />,
};

const viewForStudentQuestions: Record<keyof Required<Student>, JSX.Element> = {
  previousCountryOfInsurance: <PreviousCountryOfInsurance />,
  universityInGermany: <UniversityInGermany />,
  university: <UniversityName />,
  worksMoreThanTwentyHours: <WorksMoreThanTwentyHours />,
  universityStartDate: <UniversityStartDate />,
};

const viewForOtherQuestions: Record<keyof Required<Other>, JSX.Element> = {
  otherSituation: <OtherSituation />,
  otherSituationDescription: <OtherSituationDescription />,
  previousCountryOfInsurance: <PreviousCountryOfInsurance />,
  howLongInLanguageSchool: <HowLongInLanguageSchool />,
  coverageStartDate: <CoverageStartDate />,
  jobStartDate: <BeginningOfContract />,
  employerName: <EmployerName />,
  companyAddress: <EmployerAddress />,
  jobTitle: <JobTitle />,
};

const viewForInsuranceHistory: Record<
  keyof Required<InsuranceHistory>,
  JSX.Element
> = {
  previousInsurer: <PreviousInsurer />,
  lastProviderInGermany: <LastProviderInGermany />,
  unlistedPreviousInsurer: <UnlistedPreviousInsurer />,
  previousCountryOfInsurance: <PreviousCountryOfInsurance />,
  hadOneDayPublicHealthInsurance: <HadOneDayPublicHealthInsurance />,
  hasGermanPublicHealthInsurance: <HasGermanPublicHealthInsurance />,
  hasPublicHealthInsurance: <HasPublicHealthInsurance />,
  insuranceTwelveMonths: <InsuranceTwelveMonths />,
  insuranceTwentyFourMonths: <InsuranceTwentyFourMonths />,
  pastFiveYearsInGermany: <PastFiveYearsInGermany />,
  ehicCard: <EHICCard />,
  expatOrPrivateHealthInsuranceIncoming: (
    <ExpatOrPrivateHealthInsuranceIncoming />
  ),
  currentlyOnPrivate: <CurrentlyOnPrivate />,
  lastInsuranceInGermany: <LastInsuranceInGermany />,
  employmentWithinTwoMonthsOfArrival: <EmploymentWithinTwoMonthsOfArrival />,
  switchEmployedProviders: (
    <Blocker
      id="employee-switch-provider"
      questionId="switchEmployedProviders"
    />
  ),
  switchProviders: (
    <Blocker id="switch-provider" questionId="switchProviders" />
  ),
  employerHasAlreadySignedUp: (
    <Blocker
      id="employer-has-already-signed-up"
      questionId="employerHasAlreadySignedUp"
    />
  ),
  employerMightHaveAlreadySignedUp: (
    <Blocker
      id="employer-might-have-already-signed-up"
      questionId="employerMightHaveAlreadySignedUp"
    />
  ),
  miniJobInsuredEU: (
    <Blocker id="mini-job-insured-eu" questionId="miniJobInsuredEU" />
  ),
};

const viewForFinalInformations: Record<
  keyof Required<FinalInformations>,
  JSX.Element
> = {
  germanPensionNumber: <GermanPensionNumber />,
  lastNameAtBirth: <LastNameAtBirth />,
  placeOfBirth: <PlaceAndCountryOfBirth />,
  nationality: <Nationality />,
  receivesPension: <ReceivesPension />,
  hasDependentsToCover: <DependentsToGetCovered />,
  hasDependentChildren: <HasDependentChildren />,
};

const viewForSelfEmployedQuestions: Record<
  keyof Required<SelfEmployed>,
  JSX.Element
> = {
  coverageStartDate: <CoverageStartDate />,
};

const viewForTaxInformations: Record<
  keyof Required<TaxInformation>,
  JSX.Element
> = {
  hasTaxNumber: <HasTaxNumber />,
  monthlyIncomeForNextYear: <MonthlyIncomeForNextYear />,
  agenturFurArbeitBenefits: <AgenturFurArbeitBenefits />,
  hasOtherIncome: <HasOtherIncome />,
  otherIncome: <OtherIncome />,
  otherIncomeMonthlyRevenue: <OtherIncomeMonthlyRevenue />,
};

const viewForQuestion: Record<QuestionId, JSX.Element> = {
  ...viewForGeneralQuestions,
  ...viewForEmployedQuestions,
  ...viewForStudentQuestions,
  ...viewForOtherQuestions,
  ...viewForSelfEmployedQuestions,
  ...viewForInsuranceHistory,
  ...viewForFinalInformations,
  ...viewForTaxInformations,
  questionnaireId: <></>,
};
