import { Field } from 'react-final-form';

import styles from './style.module.scss';

const SelectorQuestion = ({
  name,
  type,
  values,
}: {
  name: string;
  type: 'radio' | 'checkbox';
  values: Array<{ value: string; text: string }>;
}) => (
  <div className={`wmx5 ${styles.container}`}>
    {values.map(({ value, text }) => (
      <div className="p-label-container" key={value}>
        <Field
          className={type === 'checkbox' ? 'p-checkbox' : 'p-radio'}
          id={value}
          component="input"
          name={name}
          type={type}
          value={value}
        />
        <label
          data-cy={`${name}-${value}`}
          className="p-label  p-label--bordered"
          htmlFor={value}
        >
          {text}
        </label>
      </div>
    ))}
  </div>
);

export default SelectorQuestion;
