import { QuestionnaireState } from 'reducers/questionnaire';
import { getSalaryType } from 'util/getSalaryType';

export function serializeQuestionnaire(questionnaire: QuestionnaireState) {
  if (questionnaire.occupation === 'JOB_OFFER') {
    questionnaire = { ...questionnaire, occupation: 'EMPLOYED' };
  }

  const annualIncome = questionnaire?.annualIncome;
  const occupation = questionnaire?.occupation;
  const {
    previousInsurer,
    unlistedPreviousInsurer,
    lastProviderInGermany,
    firstTimeEmployedInGermany = true,
    hasPublicHealthInsurance = false,
    hasGermanPublicHealthInsurance = false,
  } = questionnaire;

  const publiclyInsuredInGermany =
    hasGermanPublicHealthInsurance ||
    (hasPublicHealthInsurance &&
      questionnaire?.previousCountryOfInsurance?.code === 'DE');

  const key =
    occupation === 'EMPLOYED'
      ? 'typeOfPreviousGermanHealthInsurance'
      : 'previousHealthInsuranceType';

  const isPubliclyInsured =
    publiclyInsuredInGermany || hasPublicHealthInsurance;

  return {
    firstTimeEmployedInGermany,
    ...questionnaire,
    previousInsurer:
      unlistedPreviousInsurer || previousInsurer || lastProviderInGermany,
    ...(annualIncome ? { salary: getSalaryType(annualIncome) } : {}),
    ...(publiclyInsuredInGermany
      ? {
          previousCountryOfInsurance: {
            code: 'DE',
            name: 'Germany',
          },
          hasPublicHealthInsurance: true,
          hasGermanPublicHealthInsurance: true,
          [key]: 'PUBLIC_HEALTH',
        }
      : {
          hasGermanPublicHealthInsurance,
          hasPublicHealthInsurance,
          [!isPubliclyInsured
            ? 'previousHealthInsuranceType'
            : key]: isPubliclyInsured ? 'PUBLIC_HEALTH' : 'PRIVATE_HEALTH',
        }),
  };
}
