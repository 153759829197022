import { provider, providerDomainMapping } from '../util/environment';
import Cookies from 'js-cookie';

interface TrackingObject {
  source?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  int_source?: string;
  int_content?: string;
  employerName?: string;
}

const getCookieDomain = () => {
  if (process.env.NODE_ENV === 'development') {
    return undefined;
  }

  const featherDomain = 'feather-insurance.com';

  const domain = window.location.hostname.endsWith(featherDomain)
    ? featherDomain
    : providerDomainMapping[provider];

  if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return `staging.${domain}`;
  }

  return domain;
};

/**
 * @deprecated
 *
 * We are deprecating this package in favor of https://github.com/getPopsure/tracker
 */
export class SourceTracker {
  /**
   * @deprecated
   *
   * Use `populateTrackingObjectFromUrl ` from @getPopsure/tracker
   */
  public static setSource(query: TrackingObject) {
    const { source, utm_source, int_source, employerName } = query;
    const domain = getCookieDomain();

    const config = {
      expires: 30,
      ...(domain ? { domain } : {}),
    };

    if (source) {
      Cookies.set('source', source, config);
    }

    if (utm_source || int_source || employerName) {
      const {
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        int_source,
        int_content,
      } = query;
      Cookies.set(
        'source',
        JSON.stringify({
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
          int_source,
          int_content,
          employerName,
        }),
        config
      );
    }
  }

  /**
   * @deprecated
   *
   * Use `getTrackingObject` from @getPopsure/tracker instead
   */
  public static getTrackingMetaData(): TrackingObject {
    const source = Cookies.getJSON('source');
    if (typeof source === 'string') {
      return { source };
    }

    return { ...source };
  }
}
