import { AxiosError } from 'axios';

export type RequestType =
  | 'SUBMIT_QUESTIONNAIRE'
  | 'SUBMIT_SIGNATURE'
  | 'post.referral.eligible'
  | 'post.referral.verify'
  | 'signout.user';

export type RequestAction =
  | { type: 'REQUEST_IN_PROCESS'; inProcess: boolean; requestType: RequestType }
  | {
      type: 'REQUEST_ERRORED';
      error: AxiosError;
      requestType: RequestType;
    }
  | {
      type: 'FLUSH_REQUEST_ERROR';
      requestType: RequestType;
    };

export function setRequestInProcess(requestType: RequestType): RequestAction {
  return { type: 'REQUEST_IN_PROCESS', inProcess: true, requestType };
}

export function setRequestFinished(requestType: RequestType): RequestAction {
  return { type: 'REQUEST_IN_PROCESS', inProcess: false, requestType };
}

export function setRequestErrored(
  requestType: RequestType,
  error: AxiosError
): RequestAction {
  return { type: 'REQUEST_ERRORED', error, requestType };
}

export function flushRequestError(requestType: RequestType): RequestAction {
  return { type: 'FLUSH_REQUEST_ERROR', requestType };
}
