import { connect } from 'react-redux';

import Footer from 'components/footer';
import { AppState } from 'reducers';
import { QuestionId } from 'reducers/questionnaire';
import { getProgress } from 'reducers/questionnaire/progress';
import { getQuestionBefore } from 'reducers/questionnaire/order';

interface StateProps {
  progress: number;
  previousScreen?: string;
}

interface OwnProps {
  questionId: QuestionId;
}

export type ViewProps = StateProps & OwnProps;

const mapStateToProps = (state: AppState, { questionId }: OwnProps) => {
  const previousScreen = getQuestionBefore(state.questionnaire, questionId);

  return {
    progress: getProgress(state.questionnaire, questionId),
    previousScreen,
  };
};
export default connect<StateProps, {}, OwnProps, AppState>(mapStateToProps)(
  Footer
);
