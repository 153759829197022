import { Icon } from '@popsure/dirty-swan/dist/cjs/lib/components/cards/icons';
import styles from './style.module.scss';

export interface CardInfo {
  id?: number;
  title: string;
  description: string;
  icon: Icon;
}

const Card = (props: CardInfo) => (
  <div className={`ds-card ds-card--no-dropshadow ${styles.container}`}>
    <div className={styles['img-container']}>
      <img src={props.icon.src} alt={props.icon.alt} />
    </div>
    <h4 className="p-h4 mt16 ta-center">{props.title}</h4>
    <p className="p-p--small tc-grey-600 mt8 ta-center">{props.description}</p>
  </div>
);

export default Card;
