import { AutocompleteAddress } from '@popsure/dirty-swan';
import { Field } from 'react-final-form';

const AddressQuestion = ({ name }: { name: string }) => {
  return (
    <>
      <Field name={name}>
        {(props) => {
          return (
            <AutocompleteAddress
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
              address={props.input.value}
              onAddressChange={(address) => props.input.onChange(address)}
            />
          );
        }}
      </Field>
    </>
  );
};

export default AddressQuestion;
