import { Link } from 'react-router-dom';

import styles from './style.module.scss';
import { ViewProps } from 'containers/footer';

const Footer = ({ progress, previousScreen }: ViewProps) => (
  <div className={styles.footer}>
    {previousScreen ? (
      <Link
        data-cy="go-previous-step"
        className={styles.back}
        to={previousScreen}
        replace={false}
      />
    ) : (
      <button className={styles.back} disabled={true} />
    )}
    <div className={styles['progress-container']}>
      <div className={styles.progress} style={{ width: `${progress}%` }} />
    </div>
  </div>
);

export default Footer;
