import { getQuestionOrder } from 'reducers/questionnaire/order';

export const routes = {
  questionnaire: {
    path: '/questionnaire',
    question: {
      first: {
        path: `/questionnaire/${getQuestionOrder({})[0]}`,
      },
      path: '/questionnaire/:questionId',
    },
  },
  blocker: {
    generic: {
      path: '/blocker/:id?',
    },
    studentAboveAge: {
      path: '/blocker/student-above-age',
    },
    studentSignedUpTooLate: {
      path: '/blocker/student-signed-up-too-late',
    },
    studentTooManySemesters: {
      path: '/blocker/student-too-many-semesters-studied',
    },
    studentWorkingTwentyHours: {
      path: '/blocker/student-working-twenty-hours',
    },
    universityOutsideOfGermany: {
      path: '/blocker/university-outside-germany',
    },
    otherPreviouslyPrivate: {
      path: '/blocker/other-previous-private',
    },
    otherPreviousTravel: {
      path: '/blocker/other-previous-travel',
    },
    intern: {
      path: '/blocker/intern',
    },
    phdDoctorate: {
      path: '/blocker/phd-doctorate',
    },
    spouse: {
      path: '/is-spouse',
    },
    familyReunionVisa: {
      path: '/family-reunion-visa',
    },
    employerHasAlreadySignedUp: {
      path: '/blocker/employer-has-already-signed-up',
    },
    employerMightHaveAlreadySignedUp: {
      path: '/blocker/employer-might-have-already-signed-up',
    },
    employeeSwitchProvider: {
      path: '/blocker/employee-switch-provider',
    },
    switchProvider: {
      path: '/blocker/switch-provider',
    },
    ineligible: {
      path: '/blocker/ineligible',
    },
    ineligibleStudent: {
      path: '/blocker/ineligible-student',
    },
    ineligiblePrivate: {
      path: '/blocker/ineligible-private',
    },
    ineligibleTwoMonthsAfterArrival: {
      path: '/blocker/ineligible-two-months-after-arrival',
    },
    weCanHelpStudent: {
      path: '/blocker/we-can-help-student',
    },
  },
  signature: {
    path: '/signature',
  },
  nextSteps: {
    path: '/next-steps',
  },
  studentNextSteps: {
    path: '/student-next-steps',
  },
};
