import { Field } from 'react-final-form';
import { detectLocale, Locale } from '@getpopsure/i18n-react';
import { AutoSuggestMultiSelect } from '@popsure/dirty-swan';
import {
  Country,
  getAllCountries,
  countryNameFromAlphaCode,
  associatedCountryFlagForCountryCode,
} from '@getpopsure/public-models';

import styles from './style.module.scss';
import { useTranslation } from '@getpopsure/i18n-react';

const CountryMultiQuestion = ({ name }: { name: string }) => {
  const locale = detectLocale() as Locale;
  const allCountries = getAllCountries(locale);
  const { t } = useTranslation();
  return (
    <Field name={name}>
      {(props) => (
        <div className={`${styles.container}`}>
          <AutoSuggestMultiSelect
            options={allCountries.map((country) => ({
              value: country.name,
              leftIcon: associatedCountryFlagForCountryCode(country.code),
            }))}
            setValues={(values) => {
              const selectedCountriesWithCode = allCountries.filter((country) =>
                values.map((value) => value.value).includes(country.name)
              );
              props.input.onChange(selectedCountriesWithCode);
            }}
            selectedValues={
              props.input.value && props.input.value.length > 0
                ? props.input.value.map((country: Country) => ({
                    value: countryNameFromAlphaCode(country.code, locale),
                    leftIcon: associatedCountryFlagForCountryCode(country.code),
                  }))
                : []
            }
            placeholder={t(
              'page.question.multicountry.input.placeholder',
              'Country'
            )}
            chipsListClassName="wmx9"
            multiSelectClassName="wmx5"
          />
        </div>
      )}
    </Field>
  );
};

export default CountryMultiQuestion;
