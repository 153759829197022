import { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';

type ComponentProps = {
  children: ReactNode;
};

const Link = (openLinksInNewTab: boolean) => ({
  children,
  href,
}: ComponentProps & {
  href: string;
}) =>
  openLinksInNewTab ? (
    <a href={href} className="p-a" target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <a href={href} className="p-a">
      {children}
    </a>
  );

const Paragraph = (className: string) => ({ children }: ComponentProps) => (
  <p className={className || 'p-p mt8'}>{children}</p>
);

const Heading = ({ children, level }: ComponentProps & { level: number }) => {
  switch (level) {
    case 1:
      return <h1 className="p-h1 p--serif mt32">{children}</h1>;
    case 2:
      return <h2 className="p-h2 mt24">{children}</h2>;
    case 3:
      return <h3 className="p-h3 mt16">{children}</h3>;
    case 4:
      return <h4 className="p-h4 mt8">{children}</h4>;
    default:
      return <p>{children}</p>;
  }
};

const Strong = ({ children }: ComponentProps) => {
  return <strong className="fw-bold">{children}</strong>;
};

const Emphasis = ({ children }: ComponentProps) => {
  return <em className="fs-italic">{children}</em>;
};

const Markdown = ({
  children,
  className = '',
  openLinksInNewTab = false,
  paragraphClassName = '',
}: {
  children: ReactNode;
  className?: string;
  openLinksInNewTab?: boolean;
  paragraphClassName?: string;
}) => (
  <ReactMarkdown
    className={className}
    renderers={{
      link: Link(openLinksInNewTab),
      paragraph: Paragraph(paragraphClassName),
      heading: Heading,
      strong: Strong,
      emphasis: Emphasis,
    }}
  >
    {children}
  </ReactMarkdown>
);

export default Markdown;
