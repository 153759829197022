import { OutboundLink } from 'react-ga';
import { faq } from '@getpopsure/private-constants';
import { useTranslation } from '@getpopsure/i18n-react';
import familyIcon from '../img/family.svg';
import styles from './style.module.scss';

const FamilyReunionVisa = () => {
  const { t } = useTranslation();

  return (
    <div className="p-body public-signup-center-element">
      <div className={`wmx8 ${styles.container}`}>
        <div className={styles.icon}>
          <img src={familyIcon} alt="family" />
        </div>
        <div className={`p-h1 ${styles.title}`}>
          {t('page.blocker.familyreunionBlocker.title', "We're sorry")}
        </div>
        <div className={`p-p ${styles.subtitle}`}>
          {t(
            'page.blocker.familyreunionBlocker.description',
            'Unfortunately, we cannot help you sign up for public health insurance at this time. You can find out more about the health insurance options for a family reunion visa.'
          )}
        </div>
        <OutboundLink
          eventLabel="cant_get_covered_familyreunion_faq_link"
          className={`p-btn p-btn--primary wmn3 ${styles.button}`}
          to={`${faq.base}/en/articles/30739-health-insurance-for-a-family-reunion-visa`}
        >
          {t('page.blocker.familyreunionBlocker.action', 'Read our FAQ')}
        </OutboundLink>
      </div>
    </div>
  );
};

export default FamilyReunionVisa;
