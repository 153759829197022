import { QuestionId, TypeForQuestionId } from 'reducers/questionnaire';

export interface AnsweredQuestionAction {
  type: 'ANSWERED_QUESTION';
  questionId: QuestionId;
  answer: TypeForQuestionId<QuestionId>;
  redirect: boolean;
}

export interface SkippedQuestionAction {
  type: 'SKIPPED_QUESTION';
  questionId: QuestionId;
}

export interface ClearQuestionnaireAction {
  type: 'CLEAR_QUESTIONNAIRE';
}

export interface FlushQuestionsAction {
  type: 'FLUSH_QUESTIONS';
  questions: QuestionId[];
}

export interface CleanQuestionsAction {
  type: 'CLEAN_QUESTIONS';
}

export type QuestionnaireAction =
  | AnsweredQuestionAction
  | ClearQuestionnaireAction
  | SkippedQuestionAction
  | FlushQuestionsAction
  | CleanQuestionsAction;

export function answeredQuestion<Q extends QuestionId>(
  questionId: Q,
  answer: TypeForQuestionId<Q>,
  redirect: boolean = true
): AnsweredQuestionAction {
  return {
    type: 'ANSWERED_QUESTION',
    questionId,
    answer,
    redirect,
  };
}

export function skippedQuestion(questionId: QuestionId): SkippedQuestionAction {
  return {
    type: 'SKIPPED_QUESTION',
    questionId,
  };
}

export function clearQuestionnaire(): QuestionnaireAction {
  return { type: 'CLEAR_QUESTIONNAIRE' };
}

export function flushQuestionsAction(
  questions: QuestionId[]
): QuestionnaireAction {
  return { type: 'FLUSH_QUESTIONS', questions };
}

export function cleanQuestionsAction(): QuestionnaireAction {
  return { type: 'CLEAN_QUESTIONS' };
}
