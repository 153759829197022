import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { SignaturePad } from '@popsure/dirty-swan';
import { legal, website } from '@getpopsure/private-constants';
import { Trans, useTranslation } from '@getpopsure/i18n-react';
import { routes } from 'routes';
import { ViewProps } from 'containers/signature';

import styles from './style.module.scss';

import signatureIcon from './img/signature.svg';
import { provider } from 'util/environment';
import AokBillingConsent from './components/AokBillingConsent';
import classNames from 'classnames';

const Signature = ({
  isLoading,
  error,
  onSubmitSignature,
  questionnaireId,
}: ViewProps) => {
  const { t } = useTranslation();
  const [signature, setSignature] = useState('');
  const [brokerMandateAccepted, setBrokerMandateAccepted] = useState(false);
  const [
    barmerDataProtectionAgreement,
    setBarmerDataProtectionAgreement,
  ] = useState(provider !== 'barmer');
  const [aokBillingConsentAccepted, setAokBillingConsentAccepted] = useState(
    provider !== 'aok'
  );

  const valid =
    signature.length > 0 &&
    brokerMandateAccepted &&
    barmerDataProtectionAgreement &&
    aokBillingConsentAccepted;

  if (!questionnaireId) {
    return <Redirect to={routes.questionnaire.question.first.path} />;
  }

  const handleSubmitSignature = () => {
    onSubmitSignature(signature, questionnaireId);
  };

  return (
    <>
      <div className={`p-body ${styles.container}`}>
        <img className="mt24" src={signatureIcon} alt="pen writing signature" />
        <h1 className={`p-h1 mt16 ${styles.title}`}>
          {t('page.signature.title', 'Ready to submit?')}
        </h1>
        <div className={`p-p wmx10 ${styles.subtitle}`}>
          {t(
            'page.signature.description',

            'In order to start processing your application, we need a clear signature as a means of consent.'
          )}
        </div>
        <div className={`mt24 wmx10 ${styles['signature-container']}`}>
          <SignaturePad onChange={setSignature} />
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {provider === 'aok' && (
              <AokBillingConsent
                accepted={aokBillingConsentAccepted}
                setAccepted={setAokBillingConsentAccepted}
              />
            )}
            <input
              id="brokerMandate"
              className="p-checkbox"
              type="checkbox"
              checked={brokerMandateAccepted}
              onChange={() => {
                setBrokerMandateAccepted(!brokerMandateAccepted);
              }}
            />
            <label
              data-cy="signature-broker-mandate"
              htmlFor="brokerMandate"
              className="p-p d-block"
            >
              <Trans i18nKey="page.signature.legal">
                I have read and understood the{' '}
                <a
                  className="p-a"
                  href={website.privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy
                </a>
                ,{' '}
                <a
                  className="p-a"
                  href={website.termsAndConditions}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  T&Cs
                </a>{' '}
                and{' '}
                <a
                  className="p-a"
                  href={legal.brokerMandate}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Feather broker mandate
                </a>{' '}
                and I hereby confirm that I give my full consent.
              </Trans>
            </label>
            {provider === 'barmer' && (
              <>
                <input
                  id="barmerDataProtectionAgreement"
                  className="p-checkbox"
                  type="checkbox"
                  checked={barmerDataProtectionAgreement}
                  onChange={() => {
                    setBarmerDataProtectionAgreement(
                      !barmerDataProtectionAgreement
                    );
                  }}
                />
                <label
                  className="p-p d-block mt16"
                  htmlFor="barmerDataProtectionAgreement"
                >
                  <Trans i18nKey="page.signature.legal.barmer">
                    <span data-cy="signature-barmer-data-protection-agreement">
                      I have read and agree to the
                    </span>{' '}
                    <a
                      className="p-a"
                      href="https://www.barmer.de/consent/bestmoeglicher-schutz-fuer-ihre-daten-317596"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BARMER data protection agreement.
                    </a>
                  </Trans>
                </label>
              </>
            )}
          </form>
          {error && (
            <div className={`p-p p-notice p-notice--danger ${styles.error}`}>
              {error}
            </div>
          )}
        </div>
        <div className="w100 wmx5">
          <button
            data-cy="signature-submit"
            className={classNames('p-btn p-btn--primary w100 mt24', {
              'p-btn--loading': isLoading,
            })}
            disabled={!valid || isLoading}
            onClick={handleSubmitSignature}
          >
            {t('page.signature.legal.action', 'Submit')}
          </button>
        </div>
      </div>
    </>
  );
};

export default Signature;
