import { useState } from 'react';
import { Field } from 'react-final-form';
import { AutoSuggestInput } from '@popsure/dirty-swan';
import {
  allCountries,
  associatedCountryFlagForCountryCode,
} from '@getpopsure/public-models';
import { useTranslation } from '@getpopsure/i18n-react';

import styles from './style.module.scss';

interface Option {
  value: string;
  leftIcon?: string;
}

const CountrySingleComponent = ({ ...props }) => {
  const { t } = useTranslation();
  const [currentOption, setCurrentOption] = useState(
    props.input.value?.name ?? ''
  );
  const [suggestions, setSuggestions] = useState<Option[]>([]);

  return (
    <div className={`${styles.container}`}>
      <AutoSuggestInput
        currentOption={currentOption}
        className="wmx5"
        placeholder={t(
          'page.question.singlecountry.input.placeholder',
          'Country'
        )}
        suggestions={suggestions}
        onChange={(value) => {
          setCurrentOption(value);
          const selectedCountry = allCountries.find(
            (country) => country.name === value
          );
          props.input.onChange(selectedCountry);
        }}
        handleSuggestionSelected={(value) => {
          setCurrentOption(value.value);
          const selectedCountry = allCountries.find(
            (country) => country.name === value.value
          );
          props.input.onChange(selectedCountry);
        }}
        handleSuggestionFetchRequest={({ value }) => {
          const filteredOptions = allCountries
            .filter((country) =>
              country.name.toLowerCase().startsWith(value.toLowerCase())
            )
            .map((country) => ({
              value: country.name,
              leftIcon: associatedCountryFlagForCountryCode(country.code),
            }));
          setSuggestions(filteredOptions);
        }}
        handleSuggestionClearRequest={() => setSuggestions([])}
      />
    </div>
  );
};

const CountrySingleQuestion = ({ name }: { name: string }) => (
  <Field name={name}>{(props) => <CountrySingleComponent {...props} />}</Field>
);

export default CountrySingleQuestion;
