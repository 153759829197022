import { Field } from 'react-final-form';

import { CurrencyInput, InformationBox } from '@popsure/dirty-swan';
import { useState } from 'react';
import debounce from 'lodash.debounce';
import AnimateHeight from 'react-animate-height';
import { InformationBoxProps } from '@popsure/dirty-swan';

const CurrencyQuestion = ({
  name,
  placeholder = '',
  showMessage,
}: {
  name: string;
  placeholder?: string;
  showMessage?: {
    min?: number;
    max?: number;
    infoBoxProps: InformationBoxProps;
  };
}) => {
  const [messageVisible, setMessageVisible] = useState(false);
  const shouldShowMessage = (
    min?: number,
    max?: number,
    value?: number
  ): boolean => {
    if (!value || (!min && !max)) {
      return false;
    }

    if (min && value < min) {
      return true;
    }

    return !!max && value > max;
  };

  const initDebounce = debounce(
    (newValue) =>
      setMessageVisible(
        shouldShowMessage(showMessage?.min, showMessage?.max, newValue)
      ),
    500
  );

  return (
    <Field name={name}>
      {(props) => (
        <>
          <CurrencyInput
            className="wmx5"
            data-cy={name}
            onChange={(newValue) => {
              initDebounce(newValue);
              props.input.onChange(newValue);
            }}
            placeholder={placeholder}
            value={props.input.value}
          />

          {showMessage?.infoBoxProps && (
            <AnimateHeight duration={500} height={messageVisible ? 'auto' : 0}>
              <InformationBox
                className="wmx5 mt24"
                {...showMessage?.infoBoxProps}
              />
            </AnimateHeight>
          )}
        </>
      )}
    </Field>
  );
};

export default CurrencyQuestion;
