import { UserReferralInfo } from '../models';
import { AnyAction } from 'redux';

export const verifyReferralCode = (referralCode: string): AnyAction => ({
  type: 'REFERRAL_CODE_VERIFY',
  referralCode,
});

export const updateReferralInfo = (
  referralInfo: UserReferralInfo
): AnyAction => ({
  type: 'UPDATE_REFERRAL_CODE_INFO',
  referralInfo,
});

export type ReferralEngineActions =
  | { type: 'REFERRAL_CODE_ELIGIBLE_USER' }
  | { type: 'REFERRAL_CODE_VERIFY'; referralCode: string }
  | { type: 'UPDATE_REFERRAL_CODE_INFO'; referralInfo: UserReferralInfo };

export function flushReferralEngineError(): AnyAction {
  return {
    type: 'UPDATE_REFERRAL_CODE_INFO',
    referralInfo: { referralCodeError: undefined },
  };
}
