import { User } from 'models';

export type AutoFillUserAction = {
  type: 'AUTOFILL_USER';
  user: User;
};

export type SignOutUserAction = {
  type: 'SIGN_OUT';
};

export const autofillAnswerFromUserAction = (
  user: User
): AutoFillUserAction => {
  return { type: 'AUTOFILL_USER', user };
};

export const signOutUserAction = (): SignOutUserAction => {
  return { type: 'SIGN_OUT' };
};
