import { useCallback, useState } from 'react';
import { Field } from 'react-final-form';
import { AutoSuggestInput } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import styles from './style.module.scss';
import {
  Insurer,
  INSURERS,
} from '@getpopsure/public-health-insurance-pricing-engine';

interface Option {
  value: Insurer;
}

const insurersOptions = INSURERS.map((insurerName: Insurer) => ({
  value: insurerName,
}));

const PublicInsuranceQuestionComponent = ({ ...props }) => {
  const { t } = useTranslation();
  const [currentOption, setCurrentOption] = useState(props.input.value ?? '');

  const [suggestions, setSuggestions] = useState<Option[]>([]);
  const findInsurer = useCallback(
    (value) =>
      insurersOptions.find((insurers) => insurers.value === value)?.value,
    []
  );

  const handleSuggestionFetchRequest = ({ value }: Option) => {
    const filteredOptions = insurersOptions
      .filter((insurers) =>
        insurers.value.toLowerCase().startsWith(value.toLowerCase())
      )
      .map((insurer) => ({
        value: insurer.value,
      }));
    setSuggestions(filteredOptions);
  };

  return (
    <div className={`${styles.container}`}>
      <AutoSuggestInput
        currentOption={currentOption}
        className="wmx5"
        placeholder={t(
          'page.question.previousinsurer.placeholder',
          'Provider’s name'
        )}
        suggestions={suggestions}
        onChange={(value) => {
          setCurrentOption(value);
          props.input.onChange(findInsurer(value));
        }}
        handleSuggestionSelected={(value) => {
          setCurrentOption(value.value);
          props.input.onChange(findInsurer(value.value));
        }}
        handleSuggestionFetchRequest={handleSuggestionFetchRequest}
        handleSuggestionClearRequest={() => setSuggestions([])}
      />
    </div>
  );
};

const CountrySingleQuestion = ({ name }: { name: string }) => (
  <Field name={name}>
    {(props) => <PublicInsuranceQuestionComponent {...props} />}
  </Field>
);

export default CountrySingleQuestion;
