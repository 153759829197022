import { Field, useFormState } from 'react-final-form';
import { isMobile } from 'util/isMobile';
import styles from './style.module.scss';
import classNames from 'classnames';
import { InformationBox } from '@popsure/dirty-swan';
import { FieldValidator } from 'final-form';

const TextQuestion = ({
  name,
  placeholder,
  type,
  component = 'input',
  validate,
}: {
  name: string;
  placeholder?: string;
  type?: 'email' | 'number';
  component?: 'input' | 'textarea';
  validate?: FieldValidator<string>;
}) => {
  const isTextarea = component === 'textarea';
  const formState = useFormState();
  // Make return error from validator
  const errorMessage = formState.errors?.[name];

  return (
    <>
      <Field
        data-cy={name}
        className={classNames('p-input wmx5', {
          [styles.textarea]: isTextarea,
        })}
        inputMode={type === 'number' ? 'numeric' : undefined}
        name={name}
        component={component}
        placeholder={placeholder}
        type={type}
        autoFocus={isMobile === false}
        validate={validate}
        {...(isTextarea ? { rows: 4 } : {})}
      />

      {formState.dirty && errorMessage && (
        <InformationBox variant="warning" className="wmx5 mt16">
          {errorMessage}
        </InformationBox>
      )}
    </>
  );
};

export default TextQuestion;
