import styles from './style.module.scss';
import info from './img/info.svg';
import Markdown from 'components/markdown';

export type InfoHintType = {
  title: string;
  description: string;
};

const InfoHint = ({ title, description }: InfoHintType) => (
  <div data-cy="info-hint">
    <div className={styles.container}>
      <div>
        <div className="d-flex ai-center mb8">
          <div aria-hidden className={styles['info-icon']}>
            <img alt="" src={info} />
          </div>
          <div className="p-p p-p--small fw-bold tc-grey-900">{title}</div>
        </div>
        <Markdown paragraphClassName="ml32 p-p p-p--small">
          {description}
        </Markdown>
      </div>
    </div>
  </div>
);

export default InfoHint;
