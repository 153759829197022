import { BottomOrRegularModal } from '@popsure/dirty-swan';
import { useState, Dispatch } from 'react';
import { useTranslation, Trans } from '@getpopsure/i18n-react';

interface AokBillingConsentProps {
  accepted: boolean;
  setAccepted: Dispatch<boolean>;
}

function AokBillingConsent({ accepted, setAccepted }: AokBillingConsentProps) {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  return (
    <div className="mb16">
      <input
        id="aokBillingConsent"
        className="p-checkbox"
        type="checkbox"
        checked={accepted}
        onChange={() => {
          setAccepted(!accepted);
        }}
      />
      <label className="p-p d-block mt16" htmlFor="aokBillingConsent">
        <Trans i18nKey="page.signature.legal.aok">
          <span data-cy="signature-aok-billing-consent">I agree to the</span>{' '}
          <span onClick={() => setDisplayModal(true)} className="p-a c-pointer">
            consent of billing
          </span>
        </Trans>
      </label>
      <BottomOrRegularModal
        title={t('page.signature.legal.aok.modal.title', 'Consent of billing')}
        isOpen={displayModal}
        onClose={() => setDisplayModal(false)}
      >
        <div className="p24" style={{ whiteSpace: 'pre-wrap' }}>
          {t(
            'page.signature.legal.aok.modal.description',
            'I agree that the AOK communicates my identification data for billing purposes with Feather (Popsure Deutschland GmbH) when a membership has been established.\n\nI hereby confirm the accuracy of my statements, including address data. In the event of any changes, I will notify AOK of these of my own accord.'
          )}
        </div>
      </BottomOrRegularModal>
    </div>
  );
}

export default AokBillingConsent;
