import { Reducer } from 'redux';

import { QuestionnaireAction } from 'actions/questionnaire';
import {
  Employed,
  FinalInformations,
  GeneralInformations,
  InsuranceHistory,
  Other,
  SelfEmployed,
  Student,
  TaxInformation,
} from 'models';
import { getQuestionOrder } from './order';

export type QuestionnaireState = Partial<
  GeneralInformations &
    Student &
    Employed &
    Other &
    FinalInformations &
    SelfEmployed &
    InsuranceHistory &
    TaxInformation & {
      questionnaireId: string;
    }
>;

const questionnaire: Reducer<QuestionnaireState, QuestionnaireAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case 'ANSWERED_QUESTION':
      return { ...state, [action.questionId]: action.answer };
    case 'CLEAR_QUESTIONNAIRE':
      return {};
    case 'FLUSH_QUESTIONS':
      const stateKeys = Object.keys(state) as Array<QuestionId>;
      return stateKeys
        .filter((key) => !action.questions.includes(key))
        .reduce((acc: { [key: string]: unknown }, key) => {
          return { ...acc, [key]: state[key] };
        }, {});
    case 'CLEAN_QUESTIONS':
      const stateKeysClean = Object.keys(state) as Array<QuestionId>;
      const orderedQuestions = getQuestionOrder(state);

      return stateKeysClean
        .filter((key) => orderedQuestions.includes(key))
        .reduce((acc: { [key: string]: unknown }, key) => {
          return { ...acc, [key]: state[key] };
        }, {});
    case 'SKIPPED_QUESTION':
      return { ...state, [action.questionId]: null };
    default:
      return state;
  }
};

export default questionnaire;

export type QuestionId = keyof QuestionnaireState;
export type TypeForQuestionId<Q extends QuestionId> = QuestionnaireState[Q];
