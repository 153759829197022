import InfoHint, { InfoHintType } from 'components/hint';
import styles from './style.module.scss';
import Markdown from 'components/markdown';

export const WithTitle = ({
  title,
  subtitle,
  hint,
  children,
}: {
  title: string;
  subtitle?: React.ReactNode;
  hint?: InfoHintType;
  children: React.ReactNode;
}) => (
  <>
    <div
      data-cy="questionnaire-title"
      className={`p-h1 p-column ${styles.title}`}
    >
      {title}
    </div>
    {subtitle && (
      <div className={`p-p ${styles.subtitle}`}>
        <Markdown>{subtitle}</Markdown>
      </div>
    )}
    <div>
      <div className={styles.wrapper}>
        <div className={`p-column mt16 ${styles.question}`}>{children}</div>
        {hint && (
          <div className={`mt16 ${styles.content}`}>
            <InfoHint {...hint} />
          </div>
        )}
      </div>
    </div>
  </>
);
