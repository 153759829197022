import { connect } from 'react-redux';
import { AppState } from 'reducers';

import { submitedSignature } from 'actions/signature';
import Signature from 'components/pages/signature';
import { getRequestInProcess, getRequestErrorMessage } from 'reducers/request';

interface StateProps {
  isLoading: boolean;
  error?: string;
  questionnaireId?: string;
}

interface DispatchProps {
  onSubmitSignature: (signature: string, questionnaireId: string) => void;
}

export type ViewProps = StateProps & DispatchProps;

const mapStateToProps = (state: AppState) => {
  const isLoading = getRequestInProcess(state.request, 'SUBMIT_SIGNATURE');
  const error = getRequestErrorMessage(state.request, 'SUBMIT_SIGNATURE');
  const questionnaireId = state.questionnaire.questionnaireId;

  return {
    error,
    isLoading,
    questionnaireId,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onSubmitSignature: (signature: string, questionnaireId: string) => {
    dispatch(submitedSignature(signature, questionnaireId));
  },
});

export default connect<StateProps, DispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(Signature);
