import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@getpopsure/i18n-react';
import { useLocation } from 'react-router';

import styles from './style.module.scss';

import * as Sentry from '@sentry/browser';

import notFoundImage from './img/404.svg';

const PageNotFound = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    Sentry.captureMessage(`Page not found ${pathname}`);
  }, [pathname]);
  const { t } = useTranslation();

  return (
    <div className="p-body public-signup-center-element jc-center">
      <div>
        <img className={styles.image} src={notFoundImage} alt="Error 404" />
        <div className={`p-p ${styles.description}`}>
          {t(
            'page.404.title',
            'We’re sorry, the page you are looking for could not be found…'
          )}
        </div>
        <Link className={`p-a ${styles.homepage}`} to="/">
          {t('page.404.action', 'Go to homepage')}
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
