import { useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BottomOrRegularModal, Button, Input } from '@popsure/dirty-swan';
import { useHistory } from 'react-router';

import { WithTitle } from 'components/questionnaire/questions/withTitle';
import { formatErrorMessageFromError } from 'reducers/request';
import SignIn from 'components/signIn';
import { isMobile } from 'util/isMobile';
import { answeredQuestion } from 'actions/questionnaire';
import { email as emailValidator } from 'validators';
import { AppState } from 'reducers';
import { getQuestionAfter } from 'reducers/questionnaire/order';

import styles from './style.module.scss';
import { useTranslation } from '@getpopsure/i18n-react';
import { signOutUserAction } from 'actions/session';
import { isAuthenticatedAndSameEmail } from 'util/session/session';
import { verifyCustomerEmail } from 'services/api';

const EmailQuestion = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(
    useSelector((state: AppState) => state.questionnaire.email ?? '')
  );
  const nextQuestion = useSelector((state: AppState) =>
    getQuestionAfter(state.questionnaire, 'email')
  );
  const history = useHistory();
  const [displaySignInModal, setDisplaySignInModal] = useState(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const valid = emailValidator(value);

  const onSubmitForm = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    setLoading(true);
    setError(null);

    try {
      if (await isAuthenticatedAndSameEmail(value)) {
        dispatch(answeredQuestion('email', value));

        return;
      }

      dispatch(signOutUserAction());

      const { data } = await verifyCustomerEmail(value);

      if (data.userExists) {
        setIsExistingCustomer(true);
        setDisplaySignInModal(true);
      } else {
        dispatch(answeredQuestion('email', value));
        history.push(nextQuestion);
      }
    } catch (e) {
      setError(formatErrorMessageFromError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <WithTitle
      title={t('page.question.email.title', 'What’s your email address?')}
    >
      <form onSubmit={onSubmitForm}>
        <div className={`wmx5 ${styles.container}`}>
          <Input
            data-cy="email"
            placeholder={t('page.question.email.input.placeholder', 'Email')}
            type="email"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setIsExistingCustomer(false);
            }}
            autoFocus={isMobile === false}
          />
          <Button
            data-cy="questionnaire-continue-button"
            className="mt24 wmn3"
            loading={loading}
            disabled={!valid}
          >
            {t('page.question.email.action', 'Continue')}
          </Button>
          {error !== null && (
            <>
              <p className="p-notice--danger mt16">{error}</p>
            </>
          )}
        </div>
      </form>
      {isExistingCustomer && (
        <BottomOrRegularModal
          title={t('modal.signin.title', 'Sign in to Feather')}
          isOpen={displaySignInModal}
          onClose={() => setDisplaySignInModal(false)}
        >
          <SignIn
            email={value}
            context="EXISTING_ACCOUNT"
            onAuthenticationSuccess={() => {
              setDisplaySignInModal(false);
              setTimeout(() => {
                onSubmitForm();
              }, 300);
            }}
          />
        </BottomOrRegularModal>
      )}
    </WithTitle>
  );
};

export default EmailQuestion;
