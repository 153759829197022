import { render } from 'react-dom';
import FlagProvider from '@unleash/proxy-client-react';

import { unleashConfig } from './unleash.config';
import Root from './root';
import initializeSession from 'util/sessionProvider';

initializeSession();

declare global {
  interface Window {
    Cypress: any;
  }
}

render(
  <FlagProvider config={unleashConfig}>
    <Root />
  </FlagProvider>,
  document.getElementById('root')
);
