import * as validators from 'validators';
import { QuestionId } from 'reducers/questionnaire';
import {
  GeneralInformations,
  Employed,
  Student,
  FinalInformations,
  Other,
  SelfEmployed,
  TaxInformation,
  InsuranceHistory,
} from 'models';

type ValidatorType = (input: any) => validators.ValidationErrorTypes;

const generalQuestionValidators: Record<
  keyof Required<GeneralInformations>,
  ValidatorType
> = {
  name: validators.name,
  email: validators.email,
  dateOfBirth: validators.nonEmpty,
  gender: validators.nonEmpty,
  hasAddressInGermany: validators.nonEmpty,
  address: validators.address,
  occupation: validators.nonEmpty,
};

const employedQuestionValidators: Record<
  keyof Required<Employed>,
  ValidatorType
> = {
  annualIncome: validators.nonEmpty,
  previousCountryOfInsurance: validators.isCountry,
  firstTimeEmployedInGermany: validators.nonEmpty,
  previousInsurer: validators.nonEmpty,
  unlistedPreviousInsurer: validators.nonEmpty,
  employerName: validators.nonEmpty,
  companyAddress: validators.address,
  jobTitle: validators.nonEmpty,
  jobStartDate: validators.nonEmpty,
  coverageStartDate: validators.nonEmpty,
  confirmNotSignedUpByEmployer: validators.alwaysValid,
};

const studentQuestionValidators: Record<
  keyof Required<Student>,
  ValidatorType
> = {
  previousCountryOfInsurance: validators.isCountry,
  universityInGermany: validators.nonEmpty,
  university: validators.validUniversity,
  worksMoreThanTwentyHours: validators.nonEmpty,
  universityStartDate: validators.nonEmpty,
};

const otherQuestionValidators: Record<keyof Required<Other>, ValidatorType> = {
  otherSituation: validators.nonEmpty,
  otherSituationDescription: validators.minLength,
  previousCountryOfInsurance: validators.isCountry,
  howLongInLanguageSchool: validators.nonEmpty,
  coverageStartDate: validators.nonEmpty,
  jobStartDate: validators.nonEmpty,
  employerName: validators.nonEmpty,
  companyAddress: validators.address,
  jobTitle: validators.nonEmpty,
};

const finalQuestionValidators: Record<
  keyof Required<FinalInformations>,
  ValidatorType
> = {
  germanPensionNumber: validators.nonEmpty,
  lastNameAtBirth: validators.nonEmpty,
  placeOfBirth: validators.validPoB,
  nationality: validators.isArrayOfCountries,
  receivesPension: validators.nonEmpty,
  hasDependentsToCover: validators.nonEmpty,
  hasDependentChildren: validators.nonEmpty,
};

const selfEmployedQuestionValidators: Record<
  keyof Required<SelfEmployed>,
  ValidatorType
> = {
  coverageStartDate: validators.nonEmpty,
};

const insuranceHistoryValidators: Record<
  keyof Required<InsuranceHistory>,
  ValidatorType
> = {
  previousInsurer: validators.nonEmpty,
  lastProviderInGermany: validators.nonEmpty,
  unlistedPreviousInsurer: validators.nonEmpty,
  previousCountryOfInsurance: validators.isCountry,
  ehicCard: validators.nonEmpty,
  expatOrPrivateHealthInsuranceIncoming: validators.nonEmpty,
  hadOneDayPublicHealthInsurance: validators.nonEmpty,
  hasGermanPublicHealthInsurance: validators.nonEmpty,
  hasPublicHealthInsurance: validators.nonEmpty,
  insuranceTwelveMonths: validators.nonEmpty,
  insuranceTwentyFourMonths: validators.nonEmpty,
  pastFiveYearsInGermany: validators.nonEmpty,
  currentlyOnPrivate: validators.nonEmpty,
  lastInsuranceInGermany: validators.nonEmpty,
  employmentWithinTwoMonthsOfArrival: validators.nonEmpty,
  switchEmployedProviders: validators.alwaysValid,
  switchProviders: validators.alwaysValid,
  employerHasAlreadySignedUp: validators.alwaysValid,
  employerMightHaveAlreadySignedUp: validators.alwaysValid,
  miniJobInsuredEU: validators.alwaysValid,
};

const taxInformationValidators: Record<
  keyof Required<TaxInformation>,
  ValidatorType
> = {
  hasTaxNumber: validators.nonEmpty,
  monthlyIncomeForNextYear: validators.nonEmpty,
  agenturFurArbeitBenefits: validators.nonEmpty,
  hasOtherIncome: validators.nonEmpty,
  otherIncome: validators.nonEmpty,
  otherIncomeMonthlyRevenue: validators.nonEmpty,
};

const validator: Record<QuestionId, ValidatorType> = {
  ...generalQuestionValidators,
  ...employedQuestionValidators,
  ...otherQuestionValidators,
  ...studentQuestionValidators,
  ...selfEmployedQuestionValidators,
  ...insuranceHistoryValidators,
  ...finalQuestionValidators,
  ...taxInformationValidators,
  questionnaireId: validators.nonEmpty,
};

export default validator;
