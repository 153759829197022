import { createBrowserHistory } from 'history';
import { entrypoints } from '@getpopsure/private-constants';

import { isMicrofrontend } from 'util/environment';

const browserHistory = createBrowserHistory({
  basename: isMicrofrontend() ? entrypoints.publicSignup : '/',
});

export default browserHistory;
