import { Field } from 'react-final-form';
import { useTranslation } from '@getpopsure/i18n-react';

import styles from './style.module.scss';

const BooleanQuestion = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <Field name={name}>
        {(props) => {
          const {
            input: { value: selectedValue },
          } = props;

          return (
            <div className={`wmx5 ${styles.container}`}>
              <div className="p-label-container">
                <input
                  className="p-radio"
                  id="yes"
                  type="radio"
                  name={props.input.name}
                  value={'true'}
                  checked={selectedValue === true}
                  onChange={() => {
                    props.input.onChange(true);
                  }}
                />
                <label
                  data-cy={`${name}-true`}
                  className="p-label p-label--bordered"
                  htmlFor="yes"
                >
                  {t('page.question.boolean.yes', 'Yes')}
                </label>
              </div>
              <div className="p-label-container">
                <input
                  className="p-radio"
                  id="no"
                  type="radio"
                  name={props.input.name}
                  value={'false'}
                  checked={selectedValue === false}
                  onChange={() => {
                    props.input.onChange(false);
                  }}
                />
                <label
                  data-cy={`${name}-false`}
                  htmlFor="no"
                  className="p-label p-label--bordered"
                >
                  {t('page.question.boolean.no', 'No')}
                </label>
              </div>
            </div>
          );
        }}
      </Field>
    </>
  );
};

export default BooleanQuestion;
