export interface SignatureAction {
  type: 'SUBMITED_SIGNATURE';
  signature: string;
  questionnaireId: string;
}

export function submitedSignature(
  signature: string,
  questionnaireId: string
): SignatureAction {
  return {
    type: 'SUBMITED_SIGNATURE',
    signature,
    questionnaireId,
  };
}
