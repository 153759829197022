import { Country, isEEACountry } from '@getpopsure/public-models';

export const isPartOfEuropeanHealthNetwork = (country?: Country) =>
  !!country && (isEEACountry(country) || country.code === 'GB');

export const isPartOfEhicNetwork = (country?: Country) =>
  country &&
  (isEEACountry(country) ||
    ['BA', 'GB', 'IS', 'MA', 'MK', 'MN', 'RS', 'TN', 'TR'].includes(
      country.code
    ));
