import React, { useState } from 'react';
import {
  AutocompleteAddress,
  AutoSuggestInput,
  Input,
} from '@popsure/dirty-swan';
import { universities } from '@getpopsure/public-models';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { University } from 'models';

import style from './style.module.scss';
import { useTranslation } from '@getpopsure/i18n-react';

interface Option {
  value: string;
}

const UniveristyNameComponent = ({ ...props }) => {
  const { input } = props;
  const { t } = useTranslation();
  const [currentOption, setCurrentOption] = useState<University>({
    name: input.value.name ?? '',
    isListed: true,
  });
  const [selectedValues, setSelectedValues] = useState<Array<Option>>([]);
  const [suggestions, setSuggestions] = useState<Array<Option>>([]);
  const [unlistedUniversity, setUnlistedUniversity] = useState<boolean>(false);

  const toggleUnlistedUniversity = () => {
    setCurrentOption({ name: '', isListed: false });
    setUnlistedUniversity((state) => !state);
  };

  return (
    <div
      className={classNames(
        style.container,
        unlistedUniversity ? 'wmx8' : 'wmx5'
      )}
    >
      {unlistedUniversity ? (
        <div className="wmx8">
          <Input
            name="universityName"
            className="mb8"
            value={currentOption.name}
            onChange={(e) => {
              setCurrentOption({
                ...currentOption,
                name: e.target.value,
              });
              props.input.onChange({
                ...currentOption,
                name: e.target.value,
              });
            }}
            placeholder={t(
              'page.question.universityname.input.placeholder',
              'University name'
            )}
            data-cy="universityName"
            required={true}
          />
          <AutocompleteAddress
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
            address={props.input.value.address}
            onAddressChange={(address) =>
              props.input.onChange({
                ...currentOption,
                address,
              })
            }
          />
        </div>
      ) : (
        <>
          <AutoSuggestInput
            placeholder={t(
              'page.question.universityname.input.placeholder',
              'University name'
            )}
            currentOption={currentOption.name}
            suggestions={suggestions}
            onChange={(value) => {
              setCurrentOption({ name: value, isListed: true });
              input.onChange({ name: value });
            }}
            handleSuggestionSelected={(value) => {
              setSelectedValues([...selectedValues, value]);
              setCurrentOption({ name: value.value, isListed: true });
              input.onChange({ name: value.value, isListed: true });
            }}
            handleSuggestionFetchRequest={({ value }) => {
              const filteredOptions = universities
                .map((value) => ({ value }))
                .filter((option) =>
                  option.value.toLowerCase().includes(value.toLowerCase())
                );
              setSuggestions(filteredOptions);
            }}
            handleSuggestionClearRequest={() => setSuggestions([])}
            wrapText
          />
          <button
            type="button"
            className={`p-h4 fw-bold p-a mt8 d-block c-pointer ${style.button}`}
            onClick={toggleUnlistedUniversity}
          >
            {t(
              'page.question.universityname.notlisted.action',
              'My university is not listed'
            )}
          </button>
        </>
      )}
    </div>
  );
};

const UniveristyNameQuestion = ({ name }: { name: string }) => (
  <Field name={name}>{(props) => <UniveristyNameComponent {...props} />}</Field>
);

export default UniveristyNameQuestion;
